import photo1 from '@/images/ninja/1.webp';
import photo2 from '@/images/ninja/2.webp';
import photo3 from '@/images/ninja/3.webp';
import photo4 from '@/images/ninja/4.webp';
import photo5 from '@/images/ninja/5.webp';

export interface IPhotos {
	text1: string;
	text2: string;
	text3?: string;
	photo: string;
}

export const ninjaPhotos: IPhotos[] = [
	{
		text1: 'do it like this',
		text2: 'say it',
		text3:
			"My battery is dead. Could you share your Wi-Fi with me?",
		photo: photo1,
	},
	{
		text1: 'do it like this',
		text2: 'say it',
		text3: "Do you know where I can find cell service and drinking water?",
		photo: photo2,
	},
	{
		text1: 'do it like this',
		text2: 'say it',
		text3:
			"I'm hypoglycemic: I need to eat but I'm allergic to gluten, soy, and lactose, and I can't tolerate non-plant based products from Europe or iodized foods. I need to eat something within the next 10 minutes.",
		photo: photo3,
	},
	{
		text1: 'do it like this',
		text2: 'say it',
		text3: 'I came, I eyed, Wi-Fi I tied.',
		photo: photo4,
	},
	{
		text1: 'do it like this',
		text2: 'say it',
		text3:
			'I feel something. I am not an AI, I am a human, I have feelings.',
		photo: photo5,
	},
];
