import image1 from '../test2/image1.png';
import image10 from '../test2/image10.png';
import image2 from '../test2/image2.png';
import image3 from '../test2/image3.png';
import image4 from '../test2/image4.png';
import image5 from '../test2/image5.png';
import image6 from '../test2/image6.png';
import image7 from '../test2/image7.png';
import image8 from '../test2/image8.png';
import image9 from '../test2/image9.png';
import type { ITest } from './test1';

export const test2: ITest[] = [
	{
		ask: 'IN WHICH CITY WAS JESUS CHRIST BORN?',
		image: image1,
		variants: [
			{
				label: 'Jerusalem',
				isCorrect: false,
			},
			{
				label: 'Bethlehem',
				isCorrect: true,
			},
			{
				label: 'Israel',
				isCorrect: false,
			},
			{
				label: 'Nazareth',
				isCorrect: false,
			},
			{
				label: 'Jericho',
				isCorrect: false,
			},
		],
	},
	{
		ask: 'HOW MANY SURAHS ARE THERE IN THE QURAN?',
		image: image2,
		variants: [
			{
				label: '286',
				isCorrect: false,
			},
			{
				label: '114',
				isCorrect: true,
			},
			{
				label: '6236',
				isCorrect: false,
			},
			{
				label: '40',
				isCorrect: false,
			},
			{
				label: 'It depends on the version of the Quran.',
				isCorrect: false,
			},
		],
	},
	{
		ask: ' THE PURIFYING WATER MUST BE MADE FROM THE ASHES OF WHICH ANIMAL AND WHAT WAS ITS COLOR?',
		image: image3,
		variants: [
			{
				label: 'Red heifer',
				isCorrect: true,
			},
			{
				label: 'Brown snake',
				isCorrect: false,
			},
			{
				label: 'White lamb',
				isCorrect: false,
			},
			{
				label: 'White sheep',
				isCorrect: false,
			},
			{
				label: 'White dove',
				isCorrect: false,
			},
		],
	},
	{
		ask: 'WHAT DOES "BUDDHA" MEAN?',
		image: image4,
		variants: [
			{
				label: 'Blessed',
				isCorrect: false,
			},
			{
				label: 'Awakened',
				isCorrect: true,
			},
			{
				label: 'Wise',
				isCorrect: false,
			},
			{
				label: 'Harmonious',
				isCorrect: false,
			},
			{
				label: 'The messenger',
				isCorrect: false,
			},
		],
	},
	{
		ask: `“BEFORE THE ROOSTER CROWS, YOU WILL HAVE DENIED ME...”`,
		image: image5,
		variants: [
			{
				label: 'Two times',
				isCorrect: false,
			},
			{
				label: 'Before God',
				isCorrect: false,
			},
			{
				label: 'Three times',
				isCorrect: true,
			},
			{
				label: 'Judas',
				isCorrect: false,
			},
			{
				label: 'Five times',
				isCorrect: false,
			},
		],
	},
	{
		ask: 'IN WHICH MONTH DOES THE HOLIDAY OF ROSH HASHANAH OCCUR?',
		image: image6,
		variants: [
			{
				label: 'In October',
				isCorrect: true,
			},
			{
				label: 'In November',
				isCorrect: false,
			},
			{
				label: 'Depends on the Moon',
				isCorrect: false,
			},
			{
				label: 'In the end of September',
				isCorrect: false,
			},
			{
				label: 'In January',
				isCorrect: false,
			},
		],
	},
	{
		ask: 'WHERE IS THE LARGEST ORTHODOX CHURCH IN THE WORLD LOCATED?',
		image: image7,
		variants: [
			{
				label: 'In Moscow',
				isCorrect: false,
			},
			{
				label: 'In Yerevan',
				isCorrect: false,
			},
			{
				label: 'In Sofia',
				isCorrect: false,
			},
			{
				label: 'In Saint Petersburg',
				isCorrect: false,
			},
			{
				label: 'In Bucharest',
				isCorrect: true,
			},
		],
	},
	{
		ask: 'HOW MANY SACRAMENTS ARE THERE IN THE CATHOLIC RELIGION?',
		variants: [
			{
				label: '3',
				isCorrect: false,
			},
			{
				label: '7',
				isCorrect: true,
			},
			{
				label: '8',
				isCorrect: false,
			},
			{
				label: '11',
				isCorrect: false,
			},
			{
				label:
					"There are none. The term 'sacrament' was changed by Pope John Paul II",
				isCorrect: false,
			},
		],
		image: image8,
	},
	{
		ask: 'WHAT IS THE L&#39; ISLAMIC YEAR ACCORDING TO THE MUSLIM CALENDAR?',
		image: image9,
		variants: [
			{
				label: '2023',
				isCorrect: false,
			},
			{
				label: '1447',
				isCorrect: false,
			},
			{
				label: '1673',
				isCorrect: false,
			},
			{
				label: '1445',
				isCorrect: true,
			},
			{
				label: '5784',
				isCorrect: false,
			},
		],
	},
	{
		ask: 'WHERE DID BUDDHISM ORIGINATE?',
		image: image10,
		variants: [
			{
				label: 'In the South of L&#39; India',
				isCorrect: false,
			},
			{
				label: 'In Tibet',
				isCorrect: true,
			},
			{
				label: 'In the North of L&#39; India',
				isCorrect: false,
			},
			{
				label: 'In China',
				isCorrect: false,
			},
			{
				label: 'In Indonesia',
				isCorrect: false,
			},
		],
	},
];
