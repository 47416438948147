import { handleIndicators } from '@/features/handleIndicators';
import { handleScroll } from '@/features/handleScroll';
import slider1Img from '@/images/slider/slider1.png';
import slider2Img from '@/images/slider/slider2.jpg';
import slider3Img from '@/images/slider/slider3.png';
import slider4Img from '@/images/slider/slider4.jpg';
import slider5Img from '@/images/slider/slider5.png';
import slider6Img from '@/images/slider/slider6.jpg';
import sliderBg from '@/images/slider/sliderBg.jpg';
import Close from '@/shared/ui/Close/Close';
import { useRef, type ReactNode } from 'react';
import styles from './SliderPage.module.scss';

interface Item {
	image: string;
	text: ReactNode;
}

const sliderItems: Item[] = [
	{
		image: slider1Img,
		text: (
			<>
				<p>
					We have deforested this area, so let's repopulate it! For every resident
					settled in Amazonia, three fallow trees will be offered. Ladies, you will
					be the modern Amazons, coming from the bowels of the world to settle in
					this Sacred Land. The Amazonian climate is hot and humid all year round,
					providing an ideal temperature for anyone wishing to live in the gentle
					greenery with eccentric scents. A wild land of twenty colors and a thousand
					voices, Amazonia offers the opportunity to live in harmony and fusion with Mother
					Nature while being at the center of modern global resettlement.
				</p>
				<br />
				<p>Spaces are limited!</p>
			</>
		),
	},
	{
		image: slider2Img,
		text: (
			<>
				<p>
					Previously, only the very wealthy could travel to the moon, but the
					good news now is that it is among the priority areas to be repopulated
					. Yes, you heard right, I am indeed selling you the Moon. I could even
					pluck it for you. Because this is an urgency. It’s an ultra-cyclical 
					zone that resembles a snowball, or a dream, or a serpentine sled. The
					moon is paradise on Earth. Of course, sometimes the scenery changes; it
					is uncertain, unpredictable, moody. And then there’s Iceland...! The Polish
					proverb 'getting lost in a forest in Iceland' will no longer be relevant
					since we can finally replant it with trees from the Amazon, removed for the
					occasion when the area was cleared to accommodate the priority population.
				</p>
				<br />
				<p>
					Citizen, you must have political trust, sorry, conscience. And these zones
					need you, all of us. Everything remains to be done. The natural disaster has
					taught us not to scorn certain corners of the blue planet anymore.
				</p>
			</>
		),
	},
	{
		image: slider3Img,
		text: (
			<p>
				People say I am the twin sister of the one you left. I resemble her a bit but I cultivate
				my difference in my own way. Here, everything grows; my soils are very fertile. To live
				in me is to be at the very depths of all human fantasy. For how many decades have people 
				thought of me? Everyone wanted to tread on my carcass as if hopping. They all wanted to
				be part of it, the planet Mars! I was the unattainable, their Grail. Let me introduce
				myself, I am the planet Mars, unlike any other. I must be modest, even quite shy because
				often I blush. I am the future of planet Earth and humanity will find its salvation in m
				y warmed bosom. Now in 2037, citizens who are financially comfortable will be prioritized
				on my lands. For, even though as I said in the preamble, my lands are all very fertile,
				what grows best here is wheat and sorrel.
			</p>
		),
	},
	{
		image: slider4Img,
		text: (
			<>
				<p>
					Kafka awaits you on the shores of the Sahara, and it is neither a dream
					nor a mirage. As soon as this name is mentioned, one is catapulted into
					a welcoming and thriving desert of fire, an immensity that knows how to
					shrink to better accommodate its people. The Sahara is no longer an arid
					place. Since global warming, it has actually become colder there because
					the melting ice found its landing point on the opposite side, thousands
					of kilometers away, right in the Sahara Desert. Moreover, the Sahara
					offers a place of well-being; scientific studies have proven the
					cause-and-effect relationship between the landscapes of the Sahara and cerebral calm.
				</p>
				<br />
				<p>
					Sarah, I loved you in the hell of birds and for me alone the
					sand can separate us. If it buries both my heart and your blood
					in the same tomb guarded by a Berber.
				</p>
				<br />
				<p>
					The Sahara is a place of inspiration and a priority
					resettlement zone that promises you a new life, as flamboyant
					as the arable sun
				</p>
			</>
		),
	},
	{
		image: slider5Img,
		text: (
			<>
				<p>
					Siberia: do you know exactly where Siberia is? Well, let me tell you:
					it's the place where robots have fun! Cyber laughs... The hippest spot on
					the planet. Of all the planets... The place to be!!
				</p>
				<br />
				<p>
					You've been misled for too long with suggestions that it's freezing there
					because since the last climate disaster, the snow falls warm and its
					spectacle lasts two uninterrupted centuries in the amazed eyes of the
					new inhabitants, who feel like they are watching a black and white movie
					that God sketches from the heights of his ecstasy. A bit tilted but
					beautifully endless. Some have indeed lost their breath, but that's not
					a problem for surviving here: Siberians have a special effortless breathing;
					it's the climate that demands it, and 'it' is given to them from their first
					day here. Siberia, a priority zone of happiness, snowflakes, computers, and
					laughter. By choosing Siberia, you are on first-name terms with the future.
					And for those who love vast spaces, it's ideal; Siberia is a region in Asia,
					located in what was once Russia, spanning 13 million square kilometers and very
					rich in natural resources. 

				</p>
				<br />
				<p>
					Don't hesitate any longer!! PS: right now, the Russian government is offering land.
				</p>
			</>
		),
	},
	{
		image: slider6Img,
		text: (
			<>
				<p>
					Alaska is the 49th state of the United States, with Juneau as
					its capital and Anchorage as its largest city, home to about 40%
					of the state's population. With a total area of 1,717,854 km2, it
					is the largest and most northern state in the country, but one of
					the least populated, with only 731,545 inhabitants in 2019. But 
					that was before. We are in 2037, and many things have changed.

				</p>
				<br />
				<p>
					Like Hawaii, Alaska is separated from the contiguous United States
					and is located northwest of Canada. But that, too, was before. Now,
					there are no longer United States per se, but only neighboring states
					to be repopulated.

				</p>
				<br />
				<p>
					Alaska is at the heart of all debates. It is one of the most sought-after
					Priority Zones, especially since the Alaskan dialect, a clever blend of
					English and Indo-Mongolian, was chosen as an international language. Apartments
					have very low rent, and Alaska now has some of the most prestigious universities 
					in the world.

				</p>
				<br />
				<p>Alaska: the art of living on a grand scale.</p>
			</>
		),
	},
];

const Slider = () => {
	const items = useRef<HTMLDivElement>(null);
	const indicators = useRef<HTMLDivElement>(null);

	return (
		<div className={styles.sliderPage}>
			<img src={sliderBg} className={styles.bg} />
			<div className={styles.content}>
				<h2 className={styles.title}>PRZ</h2>
				<p className={styles.description}>
					Priority Resettlement Zones
				</p>
				<div className={styles.slider}>
					<div
						className={styles.items}
						ref={items}
						onScroll={() => handleScroll(items, indicators, styles)}
					>
						{sliderItems.map((item, index) => (
							<div
								className={styles.sliderItem}
								key={index}
								id={`slide-${index}`}
								onClick={(event) => {
									event.currentTarget.parentElement?.classList.toggle(
										styles.overflow,
									);
									document.body.classList.toggle('overflow');
									event.currentTarget.classList.toggle(styles.active);
								}}
							>
								<div className={styles.close}>
									<Close to='#' color='black' />
								</div>
								<img
									src={item.image}
									className={styles.img}
									draggable={false}
								/>
								<div className={styles.contentText}>
									<div className={styles.text}>{item.text}</div>
								</div>
							</div>
						))}
					</div>
					<div className={styles.indicators} ref={indicators}>
						{sliderItems.map((_, index) => (
							<div
								className={
									index === 0
										? `${styles.indicator} ${styles.active}`
										: styles.indicator
								}
								key={index}
								onClick={() =>
									handleIndicators({ index, indicators, items, styles })
								}
							></div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Slider;
