import WhiteLink from '@/shared/ui/WhiteLink';
import WhiteTitle from '@/shared/ui/WhiteTitle/WhiteTitle';
import styles from './Droites.module.scss';

const Droites = () => {
	return (
		<div className={styles.droitesPage} id='droites'>
			<WhiteTitle title='THE DECREES' />
			<WhiteLink to='/docs/1' text='Apatria: Defenition' />
			<WhiteLink to='/docs/2' text='Global Migration Sorting Center' />
			<WhiteLink to='/docs/3' text='Last day of choice' />
			<WhiteLink to='/docs/4' text='Decree No. 3456 of April 16, 2036' />
			<WhiteLink to='/docs/5' text='Decree No. 3457 of May 1, 2036' />
		</div>
	);
};

export default Droites;
