import labs1 from '@/images/labs/1.jpeg';
import labs2 from '@/images/labs/2.jpeg';
import labs3 from '@/images/labs/3.jpeg';
import labs4 from '@/images/labs/4.jpeg';
import labs5 from '@/images/labs/5.jpeg';
import labs6 from '@/images/labs/6.jpeg';
import Droites from '@/widgets/Droites/Droites';
import FirstPage from '@/widgets/FirstPage';
import Labs from '@/widgets/Labs/Labs';
import FingerTest from '@/widgets/Labs1Test';
import LabsVariable from '@/widgets/LabsVariable';
import Microphone from '@/widgets/Microphone';
import Questionnaire from '@/widgets/Questionnaire';
import Slider from '@/widgets/SliderPage';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PhotoPage from '../PhotoPage';

const MainPage = () => {
	const { hash } = useLocation();

	useEffect(() => {
		if (hash) {
			const scrollTo: HTMLElement | null = document.querySelector(hash);

			if (scrollTo) {
				window.scrollTo({
					top: scrollTo.offsetTop,
					behavior: 'smooth',
				});
			}
		}
	}, [hash]);
	return (
		<>
			<FirstPage />
			<Slider />
			<Labs />
			<LabsVariable
				id={1}
				img={labs1}
				text={
					<>
						<p>
							This next-generation test will partially determine your Priority Resettlement Zone destination.
						</p>
						<p>
							The shaman's test will further define your origin in its spiritual function.
						</p>
					</>
				}
			/>
			<FingerTest />
			<LabsVariable
				id={2}
				img={labs2}
				title='CHOOSE THE CORRECT ANSWER '
				text="To assess your level of knowledge of the French language and your overall general culture, please complete this test without the assistance of another person."
				buttonLink='/test/0'
				buttonText='Start the test'
			/>
			<LabsVariable
				id={3}
				img={labs3}
				text={
					<>
						<p>
							The religion assigned to you based on the results
							of this test will be crucial for your future.
						</p>
						<p>
							If necessary, you will need to undergo a conversion,
							fully supported by our services.
						</p>
					</>
				}
				buttonLink='/test2/0'
				buttonText='Start the test'
			/>
			<LabsVariable
				id={4}
				img={labs4}
				text="Sing a lullaby from your childhood into the microphone. Wait for the result that will determine the origin of this lullaby."
			/>
			<Microphone />
			<LabsVariable
				id={5}
				img={labs5}
				text={
					<>
						<p>
							Reproduce the movement you see in each image while
							repeating the survival phrases written below. Be meticulous:
							their execution must be perfect. Otherwise, the 'survival'
							stage will not be validated for certain Priority Resettlement Zones.
						</p>
					</>
				}
			/>
			<PhotoPage />
			<LabsVariable
				id={6}
				img={labs6}
				text={
					<>
						<p>
							Assess with us, step by step, your personal assets and those of your spouse.
						</p>
						<p>
							Depending on whether you choose to follow your partner to a Priority Resettlement
							Zone or not, you may be required to surrender part or all of your possessions
							to the Native French State of Law. If necessary, an express divorce can be arranged
							by our agents.

						</p>
					</>
				}
			/>
			<Droites />
			<Questionnaire
				link='https://docs.google.com/forms/d/1Rq83PlSift4s3whNw1Yub7uyTY0fnxeMmVP0ftpjHb0/viewform?edit_requested=true'
				text='Questionnaire'
			/>
		</>
	);
};

export default MainPage;
